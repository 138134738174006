export const dropShadow = (shadow) => {
    const shadows = {
        300: '0 14px 28px 0 rgba(37, 79, 115, 0.11)',
        400: '0px 0px 15px rgba(000,000,000,.2)',
        500: '0px 5px 20px -5px rgba(0, 0, 0, 0.3)',
        default: '0px 0px 15px rgba(000,000,000,.2)',
    };
    return shadows[+shadow] || shadows.default;
};

export const BREAKPOINT = '992px';

// GLOBAL STYLES
export const globalStyles = `
	/* latin */
	@font-face {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: local('Inter Regular'), local('Inter-Regular'),
			url('/static/fonts/inter-regular-optimized.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
			url('/static/fonts/inter-regular-optimized.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}
	/* latin */
	@font-face {
		font-family: 'Inter';
		font-style: normal;
		font-weight: bold;
		font-display: swap;
		src: local('Inter Heavy'), local('Inter-Heavy'),
			url('/static/fonts/inter-bold-optimized.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
			url('/static/fonts/inter-bold-optimized.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}
	html, body {
		height: 100%;
		font-family: var(--copy-font);
		color: #1C2838;
		font-size: 16px;
	}
	html {
		height: 100%;
		width: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
	}

	:root {

		--title-font: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
		--copy-font: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
		--font-base: 16px;
		--font-base-height: 25px;

		--h1-hero-size: 48px;
		--h1-size: 40px;
		--h2-size: 32px;
		--h3-size: 24px;
		--h4-size: 16px;

		—-breakpoint: 992px;
		—-breakpoint-mobile: 420px;
		—-breakpoint-desktop: 1200px;

		--success: #49a147;
		--warning: #ffad0d;
		--error: #e35141;

		--box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.3);
		--box-shadow-100: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
		0px 1px 2px rgba(0, 0, 0, 0.3);

		--gray-100: #f8f8f8;
		--gray-200: #eeeeee;
		--gray-300: #cccccc;
		--gray-400: #6a6a6a;
		--gray-500: #1a1a1a;

		--primary: #1d2758;
		--primary-100: #e4e5eb;
		--primary-200: #8e93ac;
		--primary-300: #1d2758;
		--primary-400: #151d47;


		--secondary: #eff6f7;
		--secondary-100: #E5EBF5;
		--secondary-200: #92ACD3;
		--secondary-300: #2459A9;
		--secondary-400: #163E8F;

		--tertiary: #2459a9;
		--tertiary-100: #FAFCFD;
		--tertiary-200: #F4F9F9;
		--tertiary-300: #EFF6F7;
		--tertiary-400: #E4EFF1;

		--interactive: #c77124;
		--interactive-100: #f8eee5;
		--interactive-200: #e3b892;
		--interactive-300: #c77124;
		--interactive-400: #ba5e1b;

		--accent-200: #F4F9F9;

		--focus: #005FCC;

		--gradient-100: linear-gradient(183.11deg, rgba(248, 238, 229, 0.4) 2.57%, rgba(229, 235, 245, 0.4) 97.42%);
		--gradient-200: linear-gradient(90deg, #EFF6F7 0.02%, #F8EEE5 99.96%);
	}

	h1 {
		font-family: var(--title-font);
		font-weight: bold;
		font-size: 36px;
		line-height: 44px;
	}
	h2 {
		font-family: var(--title-font);
		font-weight: bold;
		font-size: 30px;
		line-height: 40px;
		margin: 30px 0 24px;
	}
	h3 {
		font-family: var(--title-font);
		font-weight: bold;
		font-size: 24px;
		line-height: 32px;
		margin: 30px 0 24px;
	}
	h4 {
		font-family: var(--title-font);
		font-weight: bold;
		font-size: 20px;
		line-height: 32px;
	}

	p, span, a {
		font-family: var(--copy-font);
	}
	p {
		font-size: 16px;
		line-height: 25px;
		margin: 26px 0;
	}
	a {
		color: var(--interactive);
	}


	/* Same styles as p but can be used on <div>s */
	.bold{
		font-weight: bold;
	}
	.text-4xl {
		font-size: 36px;
		line-height: 44px;
	}
	.text-3xl {
		font-size: 30px;
		line-height: 40px;
	}
	.text-2xl {
		font-size: 24px;
		line-height: 32px;
	}
	.text-xl {
		font-size: 20px;
		line-height: 32px;
	}
	.text-lg {
		font-size: 18px;
		line-height: 28px;
	}
	.text-base {
		font-size: 16px;
		line-height: 24px;
	}
	.text-sm {
		font-size: 14px;
		line-height: 20px;
	}
	.text-xs {
		font-size: 12px;
		line-height: 16px;
	}
	.text-xxs {
		font-size: 10px;
		line-height: 16px;
	}









	button a {
		text-decoration: none;
		color: inherit;
	}
	blockquote {
		font-size: 23px;
		letter-spacing: -0.3px;
		line-height: 34px;
	}
	button {
		font-family: var(--copy-font);
		font-size: 12px;
		letter-spacing: 0;
		line-height: 15px;
		text-align: center;
		padding: 15px;
	}
	input, textarea {
		padding: 5px 10px;
		border: 1px solid var(--gray-300);
		display: block;
		font-size: 16px;
	}
	label {
		font-family: var(--copy-font);
		font-size: 14px;
		display: inline-block;
		padding-bottom: 5px;
	}
	iframe {
		max-width: 100%;
	}
	img {
		vertical-align: middle;
		max-width: 100%;
		height: auto;
	}
	ul, ol {
		padding: 0 0 0 20px;
		margin: 10px 0;
		line-height: 1.6;
	}

	ol li {
		padding-left: 10px;
	}

	hr {
		margin: 50px 0;
		border: 1px solid #f0f0f0;
	}

	.error-message {
		color: var(--error);
		padding-top: 10px;
		font-size: 13px;
		font-weight: bold;
	}

	/* Make clicks pass-through */
	#nprogress {
		pointer-events: none;
	}

	#nprogress .bar {
		background: #337fbd;

		position: fixed;
		z-index: 1031;
		top: 0;
		left: 0;

		width: 100%;
		height: 2px;
	}

	/* Fancy blur effect */
	#nprogress .peg {
		display: block;
		position: absolute;
		right: 0px;
		width: 100px;
		height: 100%;
		box-shadow: 0 0 10px #337fbd, 0 0 5px #337fbd;
		opacity: 1;

		-webkit-transform: rotate(3deg) translate(0px, -4px);
		-ms-transform: rotate(3deg) translate(0px, -4px);
		transform: rotate(3deg) translate(0px, -4px);
	}

	/* Remove these to get rid of the spinner */
	#nprogress .spinner {
		display: block;
		position: fixed;
		z-index: 1031;
		top: 15px;
		right: 15px;
	}

	#nprogress .spinner-icon {
		width: 18px;
		height: 18px;
		box-sizing: border-box;

		border: solid 2px transparent;
		border-top-color: #29d;
		border-left-color: #29d;
		border-radius: 50%;

		-webkit-animation: nprogress-spinner 400ms linear infinite;
		animation: nprogress-spinner 400ms linear infinite;
	}

	.nprogress-custom-parent {
		overflow: hidden;
		position: relative;
	}

	.nprogress-custom-parent #nprogress .spinner,
	.nprogress-custom-parent #nprogress .bar {
		position: absolute;
	}
	.bcFloat,
	.widget-floating__teaser {
		display: none !important;
	}
	@-webkit-keyframes nprogress-spinner {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes nprogress-spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	/* font_awesome styles */
	.fa,.fab,.fal,.far,.fas {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1
	}
	`;

export const px = (value) => {
    if (typeof value === 'number') {
        return value + 'px';
    } else if (typeof value === 'string') {
        /**
         * Check if already ends with px, em, rem, etc.
         */
        const hasLetters = value.match(/\d/g);

        if (hasLetters) {
            let numsOnly = hasLetters.join('');
            return numsOnly + 'px';
        }

        return value + 'px';
    } else {
        console.log('Must pass string or number to px()');
        return '25px';
    }
};

export const MAX_WIDTH = '1170';
export const MAX_WIDTH_PX = px(MAX_WIDTH);

export const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
        ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
              result[3],
              16,
          )}`
        : '0, 0, 0';
};
