import React, {useState, useEffect} from 'react';
import {useContext} from 'react';
import AppContext from '../AppContext';
import NavDrawer from './NavDrawer';
import DesktopNav from './DesktopNav';
import HiddenOnDesktop from '../HiddenOnDesktop';
import HiddenOnMobile from '../HiddenOnMobile';
import {getOrganizedMenu} from './utils';
import {useRouter} from 'next/router';
import MobileNavHeader from './MobileNav/MobileNavHeader';

export const NavContext = React.createContext();

const Navigation = (props) => {
    const {showNextStepsNav} = props;
    const {isMobile, menuItems} = useContext(AppContext);

    const {asPath, query} = useRouter();

    const menu = getOrganizedMenu(menuItems);

    const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
    const [activeSecondaryMenuId, setActiveSecondaryMenuId] = useState(null);

    const searchMenuId = menu.find((item) => item?.title == 'Search')?.ID;

    // close drawer on path change
    useEffect(() => {
        setIsNavDrawerOpen(false);
    }, [asPath, query]);

    // body overflow
    useEffect(() => {
        const body = document?.querySelector('body');
        if (!body) return;
        if (isNavDrawerOpen) {
            body.style.overflowY = 'hidden';
            body.style.overscrollBehavior = 'contain';
        } else {
            body.style.overflowY = 'unset';
            body.style.overscrollBehavior = 'auto';
        }
    }, [isNavDrawerOpen]);

    useEffect(() => {
        setActiveSecondaryMenuId(null);
    }, [asPath]);

    return (
        <NavContext.Provider
            value={{
                menu,
                isNavDrawerOpen,
                setIsNavDrawerOpen,
                activeSecondaryMenuId,
                setActiveSecondaryMenuId,
                searchMenuId,
                showNextStepsNav,
            }}>
            <NavDrawer {...props} />
            <HiddenOnDesktop>
                <MobileNavHeader />
            </HiddenOnDesktop>
            <HiddenOnMobile>
                <DesktopNav />
            </HiddenOnMobile>
        </NavContext.Provider>
    );
};
export default Navigation;
