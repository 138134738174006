export const lowIntentPhoneNumber = {
    display: '(888) 966-8152',
    href: 'tel:+1-888-966-8152',
    base: '888-966-8152',
};
export const midIntentPhoneNumber = {
    display: '(313) 536-3298',
    href: 'tel:+1-313-536-3298',
    base: '313-536-3298',
};
export const highIntentPhoneNumber = {
    display: '(928) 900-2021',
    href: 'tel:+1-928-900-2021', // (866) 464-3073
    base: '928-900-2021',
};
export const spanishPhoneNumber = {
    display: '(866) 904-4032',
    href: 'tel:+1-866-904-4032',
    base: '866-904-4032',
};

export const pinterestPagePhoneNumber = {
    display: '(866) 879-5130',
    href: 'tel:+1-866-879-5130',
    base: '866-879-5130',
};

export const alumniAppPhoneNumber = {
    display: '(408) 886-9429',
    href: 'tel:+1-408-886-9429',
    base: '408-886-9429',
};

export const outpatientPhoneNumber = {
    display: '(201) 885-4834',
    href: 'tel:+1-201-885-4834',
    base: '201-885-4834',
};

export const veteranPhoneNumber = {
    display: '(689) 306-0209',
    href: 'tel:+1-689-306-0209',
    base: '689-306-0209',
};
