const playbookId = 398539;

export const launchChat = () => {
    let driftData = sessionStorage.getItem('drift_chat') || null;
    const conversationId = JSON.parse(driftData)?.conversationId;

    if (window && window.drift && window.drift.api) {
        if (conversationId) {
            drift.api.goToConversation(Number(conversationId));
        } else {
            drift.api.startInteraction({
                interactionId: playbookId,
                replaceActiveConversation: true,
            });
        }
    }

    // heap tracking
    if (window && window.heap) {
        window?.heap?.track('Drift Start Chat', {
            ['Interaction ID']: playbookId,
        });
    }

    return;
};

export const getRandomNumber = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};
