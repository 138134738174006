import url from 'url';
import {dynamicProviders, dynamicSubstances, dynamicFacilities} from './globals';
import {toTitleCase, getSlug} from './utils';

// Helper: handles updating keywords if new one is found,
// using localstorage as secondary fallback, and finally a string fallback
const getKeyword = (isFound, storedKeywords, dynamicKey, fallback) => {
    const storedValue = storedKeywords?.[dynamicKey] || '';

    let keyword = isFound ? isFound : storedValue ? storedValue : fallback;
    return keyword.replace(/-/g, ' ');
};

// get providers based on list of given insurance slugs
const getProvider = (slug, storedKeywords) => {
    const isFound = dynamicProviders.find((item) => item === slug);
    let provider = getKeyword(isFound, storedKeywords, 'provider', 'insurance');
    return (provider = provider !== 'insurance' ? toTitleCase(provider) : provider); // capitalize if not 'insurance'
};

// get substance based on list of given words found in whole path
const getSubstance = (asPath, storedKeywords) => {
    const isFound = dynamicSubstances.find((item) => asPath.indexOf(item) !== -1);
    return getKeyword(isFound, storedKeywords, 'substance', 'substance');
};

// get facility based on list of given slugs
const getFacility = (slug, storedKeywords) => {
    const isFound = dynamicFacilities.find((item) => item === slug);
    let facility = getKeyword(
        isFound,
        storedKeywords,
        'facility',
        'An American Addiction Center Facility',
    );
    return toTitleCase(facility); // capitialize
};

export const getDynamicKeywords = (asPath) => {
    const pathname = url.parse(asPath)?.pathname || '';
    const slug = getSlug(pathname);

    // get previous keywords from localstorage for repeat visitors
    let storedKeywords = {};
    try {
        storedKeywords = (window && window.localStorage.getItem('appState')) || {};
        storedKeywords = JSON.parse(storedKeywords)?.['dynamicKeywords'];
    } finally {
        const provider = getProvider(slug, storedKeywords);
        let substance = getSubstance(asPath, storedKeywords);
        substance = asPath.includes(substance) ? substance : 'substance';
        const facility = getFacility(slug, storedKeywords);

        return {
            provider,
            substance,
            facility,
        };
    }
};

export const replaceDynamicKeywords = (str, dynamicKeywords) => {
    return str
        .replace(/{dynamic-provider}/g, dynamicKeywords['provider'])
        .replace(/{dynamic-substance}/g, dynamicKeywords['substance'])
        .replace(/{dynamic-facility}/g, dynamicKeywords['facility']);
};
