import url from 'url';
import parse from 'html-react-parser';
import {
    lowIntentPhoneNumber,
    midIntentPhoneNumber,
    highIntentPhoneNumber,
    spanishPhoneNumber,
    pinterestPagePhoneNumber,
    alumniAppPhoneNumber,
    outpatientPhoneNumber,
    veteranPhoneNumber,
} from './phone-numbers';
import {getEntryUrl, isMappableArray} from 'aac-components/utils/helpers';

export const getProp = (obj, keys, defaultValue = null) => {
    const objectKeys = keys.split('.');
    const allKeys = objectKeys.reduce((acc, key) => {
        const match = key.match(/\[\d\]$/);
        if (match) {
            const propName = key.slice(0, match.index);
            propName && acc.push(propName);
            const index = +key.slice(match.index + 1, key.length - 1);
            acc.push(index);
        } else {
            acc.push(key);
        }

        return acc;
    }, []);

    const result = allKeys.reduce((acc, cur) => {
        return acc === null || acc === undefined ? acc : acc[cur];
    }, obj);

    return result !== undefined && result !== null ? result : defaultValue;
};

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const splitPathname = (pathname) => {
    if (typeof pathname !== 'string') {
        console.log('Path must be a string');
        return;
    }
    let path = `/${pathname}/`;
    path = path.split('/');
    path = path.filter((item) => item !== '');
    return path;
};

export const getSlug = (str) => {
    if (typeof window !== 'undefined') {
        const pathname = splitPathname(str);
        return pathname[pathname.length - 1]?.split('?')?.[0];
    }
};

export const getBasePath = (str) => {
    const pathname = splitPathname(str);
    return pathname[0];
};

export const getParameterByName = (name) => {
    if (typeof window !== 'undefined') {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
};

export const isValidEmail = (input) => {
    if (typeof input !== 'string') {
        console.warn('Email input must be a sting.');
        return false;
    }
    return /.+@.+\..+/.test(input);
};

export const getElemOffset = (el) => {
    if (typeof window === 'undefined') {
        return;
    }
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
};

export const getHeadings = (html) => {
    if (!html) return [];
    // grab all the heading from the content
    const expression = /<h2.*>(.*?)<\/h2>/g;
    const matches = html.match(expression) || [];
    const headings = matches.map((item) => {
        const startString = item.indexOf('>');
        const endString = item.indexOf('</');
        const title = item.substring(startString + 1, endString);
        if (title === '') {
            return;
        } else {
            return title;
        }
    });

    return headings;
};

export const splitByWord = (str) => {
    if (typeof str !== 'string') {
        console.log('Must pass a string to kebabCase');
        return [];
    }

    /**
     * URI with spaces
     */
    if (str.match('%20')) {
        return str.split('%20');
    }

    /**
     * Spaces
     */
    if (str.match(' ')) {
        return str.split(' ');
    }

    /**
     * Dashes
     */
    if (str.match('-')) {
        return str.split('-');
    }

    /**
     * Underscores
     */
    if (str.match('_')) {
        return str.split('_');
    }

    /**
     * Camel case
     */
    if (/[A-Z]/.test(str)) {
        const splitBySpaces = str.split('').reduce((acc, letter, i) => {
            if (/[A-Z]/.test(letter) && i !== 0) {
                acc += ' ';
            }

            acc += letter;

            return acc;
        }, '');

        return splitBySpaces.split(' ');
    }

    return [str];
};

export const toCapitalCase = (str) => {
    const wordArray = splitByWord(str);

    const capitalized = wordArray.map((word) => {
        const firstLetter = word.slice(0, 1);
        const uppercase = firstLetter.toUpperCase();
        return uppercase + word.slice(1);
    });

    return capitalized.join(' ');
};

export const alphabetize = (prop) => {
    return (a, b) => {
        const aValue = prop ? getProp(a, prop) : a;
        const bValue = prop ? getProp(b, prop) : b;
        if (aValue.toLowerCase() < bValue.toLowerCase()) {
            return -1;
        } else if (aValue.toLowerCase() > bValue.toLowerCase()) {
            return 1;
        } else {
            return 0;
        }
    };
};

export const alphabetizeArrayByKey = (array, key) => {
    if (!array || !Array.isArray(array)) return [];
    const newArray = array.sort((a, b) => {
        const varA = getProp(a, key);
        const varB = getProp(b, key);
        if (varA < varB) {
            return -1;
        }
        if (varA > varB) {
            return 1;
        }
        return 0;
    });
    return newArray;
};

export const tagsToReplace = {
    '&#8211;': '-',
    '&#038;': '&',
    '&#8217;': "'",
    '&#8220;': '"',
};

/**
 * Escaping HTML entities because dangerouslySetInnerHTML
 * doesn't work with <title> tags
 * https://github.com/zeit/next.js/issues/910
 */
export const replaceHtmlEntities = (str) => {
    if (!str || typeof str !== 'string') return;
    return str.replace(/&\S+;/g, (tag) => {
        return tagsToReplace[tag] || str;
    });
};

export const removeAllHtmlTags = (str) => {
    const regex = /(<([^>]+)>)/gi;
    return str.replace(regex, '');
};

export const appendScriptFromSrc = (src, id) => {
    if (typeof src !== 'string') {
        console.warn('You must provide a string as a source');
        return;
    }
    if (typeof document === 'undefined') {
        console.warn('No document found');
        return;
    }
    // pass an id to prevent script loading twice
    if (document.getElementById(id)) return;

    const script = document.createElement('script');

    script.src = src;
    id && script.setAttribute('id', id);
    document.body.appendChild(script);
};

export const appendScript = (script, id) => {
    if (typeof script !== 'string') {
        console.warn('You must provide a string as a source');
        return;
    }
    if (typeof document === 'undefined') {
        console.warn('No document found');
        return;
    }
    // pass an id to prevent script loading twice
    if (document.getElementById(id)) return;

    const scriptEl = document.createElement('script');
    scriptEl.innerHTML = script;

    id && scriptEl.setAttribute('id', id);
    document.body.appendChild(scriptEl);
};

export const getDataFromProps = (props) => {
    const data = getProp(props, 'data') || getProp(props, 'data.data') || [];
    return data;
};

export const getStatusCode = (props) => {
    const status =
        getProp(props, 'status') ||
        getProp(props, 'data.status') ||
        getProp(props, 'data.data.status');
    return status;
};

export const shouldShowError = (props) => {
    const statusCode = getStatusCode(props);
    if (props.error) {
        return statusCode || 404;
    } else if (statusCode && statusCode !== 200) {
        return statusCode;
    } else if (getDataFromProps(props).length === 0) {
        return statusCode || 404;
    }
    return false;
};

export const isSpanishPage = (slug) => {
    if (!slug || typeof slug !== 'string') {
        return false;
    }
    // remove query if there is one
    const slugOnly = slug.includes('?') ? slug.split('?')[0] : slug;

    return slugOnly === 'esp';
};

export const getPageLang = (asPath) => {
    const pathname = splitPathname(url.parse(asPath)?.pathname);
    const slug = pathname[pathname.length - 1]?.split('?')?.[0] || '';
    return slug === 'esp' ? 'es' : 'en';
};

// https://plainjs.com/javascript/utilities/set-cookie-get-cookie-and-delete-cookie-5/
export const getCookie = (name) => {
    let v = decodeURIComponent(document.cookie).match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : '';
};

export const formatPhoneNumber = (value) => {
    if (!value || typeof value !== 'string') return '';
    let num = value.replace(/\D/g, '');
    if (value.length > 4) {
        var dash1 = '-';
    } else {
        var dash1 = '';
    }
    if (value.length > 7) {
        var dash2 = '-';
    } else {
        var dash2 = '';
    }
    value =
        num.substring(0, 3) + dash1 + num.substring(3, 6) + dash2 + num.substring(6, 10);
    return value;
};

export const formatDollarAmount = (value) => {
    if (!value || typeof value !== 'string') return '';
    return `$${value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const validateEmail = (value) => {
    const isValid = value.indexOf('@') >= 1 && value.indexOf('.') >= 1;
    return isValid;
};

export const getTimeZone = () => {
    function timezone(dt) {
        return /\((.*)\)/.exec(new Date().toString())[1];
    }

    const dt = new Date();
    const timeZone = timezone(dt);

    return timeZone;
};

export const containsUrl = (path, urls) => {
    const urlsTrailingSlash = [...urls].map((path) => {
        return `${path}/`;
    });
    const urlsBeginningSlash = [...urls].map((path) => {
        return `/${path}`;
    });
    const pathname = url.parse(path, true).pathname;
    const containsUrl =
        urls.includes(pathname) ||
        urlsTrailingSlash.includes(pathname) ||
        urlsBeginningSlash.includes(pathname);
    return containsUrl;
};

export const filterPaths = (paths) => {
    const filteredPaths = paths.reduce((acc, path) => {
        const firstCharacter = path.substring(0, 1);
        if (firstCharacter !== '?' && path.includes('?')) {
            acc.push(path.split('?')[0]);
        } else if (firstCharacter !== '#' && firstCharacter !== '?' && path !== 'page') {
            acc.push(path);
        }
        return acc;
    }, []);
    return filteredPaths;
};

export const formatDate = (value) => {
    // replace all characters other than numbers

    let num = value.replace(/\D/g, '');
    const firstNumber = parseInt(num[0]);
    if (firstNumber > 1) {
        return '';
    }

    let dash1;
    let dash2;

    if (value.length > 2) {
        dash1 = '/';
    } else {
        dash1 = '';
    }
    if (value.length > 5) {
        dash2 = '/';
    } else {
        dash2 = '';
    }

    value =
        num.substring(0, 2) + dash1 + num.substring(2, 4) + dash2 + num.substring(4, 8);

    return value;
};

export const lazyLoadImagesServerside = `
let script = document.createElement('script');
script.src =
    'https://cdn.jsdelivr.net/npm/vanilla-lazyload@12.0.0/dist/lazyload.min.js';
// Use vanilla-lazyload
window.lazyLoadOptions = {
    elements_selector: '[loading=lazyel], [loading=lazybg]'
};
let head = document.getElementsByTagName('head')[0];
head.append(script);
`;

export const lazyLoadImagesClientside = () => {
    let script = document.createElement('script');
    script.src =
        'https://cdn.jsdelivr.net/npm/vanilla-lazyload@12.0.0/dist/lazyload.min.js';
    // Use vanilla-lazyload
    window.lazyLoadOptions = {
        elements_selector: '[loading=lazyel], [loading=lazybg]',
    };
    let head = document.getElementsByTagName('head')[0];
    head.append(script);
};

export const setupLazyLoadOptions = `
    window.lazyLoadOptions = {
        elements_selector: '[loading=lazyel], [loading=lazybg]',
    };
    window.addEventListener(
        "LazyLoad::Initialized",
        function (event) {
            window.lazyLoadInstance = event.detail.instance;
        },
        false
    );
`;

export const isValidJson = (schema) => {
    let isValidJson;
    try {
        schema = JSON.parse(schema);
        isValidJson = true;
    } catch (e) {
        return null;
    }
    return isValidJson;
};

export const removeTrailingSlash = (url) => {
    const lastChar = url.substr(-1);
    if (lastChar === '/') {
        url = url.substring(0, url.length - 1);
    }
    return url;
};

export const scrollToElement = (id, marginTop = 150) => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const element = document.getElementById(id);
        if (!element) return;
        const elOffset = getElemOffset(element);

        elOffset.top &&
            window.scrollTo({
                top: elOffset.top - marginTop,
                behavior: 'smooth',
            });
    }
};

export const getSessionStorageState = (state = {}) => {
    try {
        const serializedState = sessionStorage.getItem('AacApp');
        if (serializedState === null) {
            return state;
        }
        return {...state, ...JSON.parse(serializedState)};
    } catch (e) {
        console.warn(e);
        return state;
    }
};

export const loadStateFromLocalStorage = (state = {}) => {
    try {
        const serializedState = localStorage.getItem('appState');
        if (serializedState === null) {
            return state;
        }
        return {...state, ...JSON.parse(serializedState)};
    } catch (e) {
        console.warn(e);
        return state;
    }
};

export const saveStateToLocalStorage = (state = {}) => {
    // only specific fields to localStorage
    const {
        history = [],
        callTrackingIntentScore,
        pagesScrolled50,
        lastVisitedTimestamp,
        dynamicKeywords = {},
        callTrackingNumber,
    } = state;
    try {
        const serializedState = JSON.stringify({
            history,
            dynamicKeywords, //@todo does this need to be synced
            callTrackingIntentScore,
            pagesScrolled50,
            lastVisitedTimestamp,
            callTrackingNumber,
        });
        localStorage.setItem('appState', serializedState);
    } catch (e) {
        /* log error */
        console.warn(e);
    }
};

export const getSegmentName = () => {
    if (typeof window === 'undefined') {
        return;
    }
    const el = document.getElementById('page-segment');
    const segment = (el && el.dataset.segment) || '';
    return segment;
};

export const getSegmentNumber = (segment) => {
    const segmentName = segment || getSegmentName();
    let segmentNumber = 0;
    switch (segmentName) {
        case 'other':
            segmentNumber = 0;
            break;
        case 'info-pr':
            segmentNumber = 1;
            break;
        case 'info-drug-illegal':
            segmentNumber = 2;
            break;
        case 'info-drug-otc':
            segmentNumber = 2;
            break;
        case 'info-addiction':
            segmentNumber = 2;
            break;
        case 'info-alcohol':
            segmentNumber = 2;
            break;
        case 'behavioral-addiction':
            segmentNumber = 2;
            break;
        case 'info-drug-nodetox':
            segmentNumber = 2;
            break;
        case 'info-mental-health':
            segmentNumber = 2;
            break;
        case 'info-aftercare':
            segmentNumber = 3;
            break;
        case 'search-result-page':
            segmentNumber = 3;
            break;
        case 'info-treatment':
            segmentNumber = 3;
            break;
        case 'info-treatment-drugs':
            segmentNumber = 3;
            break;
        case 'search-result-page':
            segmentNumber = 3;
            break;
        case 'insurance-pages':
            segmentNumber = 4;
            break;
        case 'facility-pages':
            segmentNumber = 4;
            break;
        case 'local-pages':
            segmentNumber = 4;
            break;
        case 'conversion-pages':
            segmentNumber = 5;
            break;
        default:
            segmentNumber = 0;
            break;
    }
    return segmentNumber;
};

export const getTags = () => {
    if (typeof window === 'undefined') {
        return;
    }
    const el = document.getElementById('page-segment');
    const tags = (el && el.dataset.tags) || '';
    return tags.replace(/\s+/g, '').split(',');
};

/* Helper function to get the the call tracking number based on intent score
 * @param {Number} callTrackingIntentScore - required
 */
export const getCallTrackingNumber = (
    callTrackingIntentScore = 0,
    path = '',
    pageProps = {},
) => {
    const {
        data: {
            acf: {
                content_segment: segment = '',
                bd_rep: {phone: bd_phone = ''} = {},
            } = {},
            slug,
        } = {},
    } = pageProps;

    const segmentNumber = getSegmentNumber(segment);
    const entryUrl = getEntryUrl() || '';

    // Alumni App Phone
    const useAlumniAppPhone =
        (entryUrl && entryUrl.includes('/alumni-app')) || slug === 'alumni-app';

    // Outpatient
    const useOutpatientPhone =
        path?.includes('outpatient') ||
        path?.includes('/rehab-guide/partial-hospitalization-programs');

    // BD Reps - if on actual bd rep page, or has value stored in local storage (user visited bd page before)
    let bd_phone_number;
    let bd_slug;
    if (bd_phone || isValidJson(window?.sessionStorage?.getItem('bd_rep'))) {
        bd_phone_number =
            bd_phone || JSON?.parse(window?.sessionStorage?.getItem('bd_rep'))?.phone;
        bd_slug = JSON?.parse(window?.sessionStorage?.getItem('bd_rep'))?.slug;
    }

    // Veteran Session
    const useVetPhone = getSessionStorageState()?.useVetPhone || false;

    // Special Pages
    const isHomepage = path === '/';
    const isInsurancePage =
        path?.includes('/insurance-coverage') || path?.includes('/verify-insurance');

    // Spanish
    const spanishPage = isSpanishPage(slug);

    // Pinterest Page
    const isPinterestPageLandingPage =
        path.includes('utm_source=pinterest') && path.includes('/recovery-is-possible');

    if (bd_phone_number) {
        return {
            display: bd_phone_number,
            href: `tel:+1-${bd_phone_number}?bd-rep=${bd_slug}`,
            base: bd_phone_number,
        };
    } else if (useAlumniAppPhone) {
        return alumniAppPhoneNumber;
    } else if (useVetPhone) {
        return veteranPhoneNumber;
    } else if (useOutpatientPhone) {
        return outpatientPhoneNumber;
    } else if (isHomepage || isInsurancePage) {
        return highIntentPhoneNumber;
    } else if (spanishPage) {
        return spanishPhoneNumber;
    } else if (isPinterestPageLandingPage) {
        return pinterestPagePhoneNumber;
    } else {
        /*
         ** The standard logic, not a special page
         */
        if (callTrackingIntentScore === 1) {
            return midIntentPhoneNumber;
        }
        if (callTrackingIntentScore === 2) {
            return highIntentPhoneNumber;
        }
        return lowIntentPhoneNumber;
    }
};

/* Helper function to get the the next higher intent call tracking number
 * @param {Object} callTrackingNumber - required
 */
export const getNextCallTrackingNumber = (callTrackingNumber = lowIntentPhoneNumber) => {
    if (callTrackingNumber.display === lowIntentPhoneNumber.display) {
        return midIntentPhoneNumber;
    }
    if (callTrackingNumber.display === midIntentPhoneNumber.display) {
        return highIntentPhoneNumber;
    }
    return callTrackingNumber;
};

export const parseYoastHead = (yoast_head) => {
    let yoastHead = yoast_head;
    if (yoastHead) {
        yoastHead = yoastHead.replace(/\/admin\./gi, '/');
        yoastHead = yoastHead.replace(/\/stagingadmin\./gi, '/');
        yoastHead = yoastHead.replace(/\/local\./gi, '/');
        yoastHead = parse(yoastHead);
    }

    return yoastHead;
};

//https:stackoverflow.com/questions/4878756/how-to-capitalize-first-letter-of-each-word-like-a-2-word-city
export const toTitleCase = (str) => {
    if (typeof str !== 'string') return '';
    return str
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
};

export const safelySerializeJSON = (i) => {
    return JSON.parse(JSON.stringify(i));
};

export const highlightString = (str, query) => {
    return query
        ? str.replace(new RegExp(query, 'gi'), (match) => `<mark>${match}</mark>`)
        : str;
};

// need to map the id in wp db to names that match other sites in AAC portfolio
export const getOldSegmentId = (segmentId) => {
    const segments = [
        {oldId: 'segment-other-0', segmentId: 'other'},
        {oldId: 'segment-pr-1', segmentId: 'info-pr'},
        {oldId: 'segment-drug-illegal-2', segmentId: 'info-drug-illegal'},
        {oldId: 'segment-drug-otc-2', segmentId: 'info-drug-otc'},
        {oldId: 'segment-alcohol-2', segmentId: 'info-alcohol'},
        {oldId: 'segment-treatment-3', segmentId: 'info-treatment'},
        {oldId: 'segment-local-4', segmentId: 'local-pages'},
        {oldId: 'segment-facility-4', segmentId: 'facility-pages'},
        {oldId: 'segment-conversion-5', segmentId: 'conversion-pages'},
        {oldId: 'segment-behavioral-addiction-2', segmentId: 'behavioral-addiction'},
    ];
    let segment = segments.filter((segment) => segment.segmentId === segmentId);
    return segment?.[0]?.oldId || segmentId;
};

export const getArticleReadTime = (html) => {
    const wpm = 600; // average words per min - we inflate this number because wp api returns chunk of text including shortcodes and tags

    const wordCount = html.split(' ').length;
    let readTime = 1;
    if (wordCount > 0) {
        readTime = Math.ceil(wordCount / wpm);
    }
    return readTime;
};

export const getArticleSectionCount = (html) => {
    const headings = getHeadings(html);
    const sectionCount = headings.length;
    if (sectionCount <= 0) return null;
    return sectionCount;
};

export const updateHtmlLang = (lang) => {
    if (document && document.documentElement) {
        document.documentElement.setAttribute('lang', lang);
    }
};

export const findCpServiceByFacilityName = (facilities, facilityName) => {
    if (!isMappableArray(facilities)) return {};
    const match = facilities?.find((x) => {
        return x?.facility_name === facilityName;
    });
    return match;
};

export const useMentalHealthVob = (tags) => {
    return (
        Array.isArray(tags) &&
        tags?.filter((tag) => tag?.slug === 'mental-health-only')?.length >= 1
    );
};

export const isExternalLink = (url) => new RegExp(/http(s?)|www/g).test(url);

/*
 * Checks if the user's current page is equal to the user's landing page
 * calltrk-calltrk_landing comes from CallRail
 */
export const isCurrentPageLP = (currentPath) => {
    // error handling for when localStorage is unavailable
    if (typeof window === 'undefined' || !window.localStorage) {
        return false;
    }

    const landingPage = localStorage.getItem('calltrk-calltrk_landing');

    return landingPage === `"https://americanaddictioncenters.org${currentPath}"`;
};
