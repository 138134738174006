import {BREAKPOINT} from './styles';
import css from 'styled-jsx/css';

export const shortcodes = css.global`
    .c-callout {
        margin: 40px 0 !important;
    }
    .c-callout p {
        margin: 0 0 24px 0;
    }
    .c-callout p:last-child {
        margin: 0;
    }
    .c-callout__title {
        margin: 0 0 30px 0;
        font-size: 18px;
        font-weight: 900;
    }
    .c-callout.c-callout--borders--all {
        background: transparent;
        border: 3px solid var(--secondary-200);
        border-radius: 8px;
        padding: 32px 24px;
    }
    .c-callout.c-callout--borders--top-bottom {
        background: transparent;
        border-top: 3px solid var(--secondary-200);
        border-bottom: 3px solid var(--secondary-200);
        border-left: none;
        border-right: none;
        border-radius: 0;
        padding: 32px 0;
    }
    .c-callout.c-callout--borders--sides {
        background: transparent;
        border-left: 3px solid var(--secondary-200);
        border-right: 3px solid var(--secondary-200);
        border-top: none;
        border-bottom: none;
        border-radius: 0;
        padding: 0 24px;
    }
    .c-columns {
        margin-bottom: 25px;
        column-gap: 40px;
    }

    .c-columns *:first-of-type {
        margin-top: 0;
    }

    .c-columns iframe {
        width: 100%;
    }

    div[class^='c-columns--'] .c-callout,
    div[class^='c-columns--'] .c-callout-complex {
        display: inline-block;
        width: 100%;
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-columns--2,
        .c-columns--3,
        .c-columns--4 {
            column-count: 2;
        }
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-columns--4 {
            column-count: 3;
        }
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-columns--3 {
            column-count: 3;
        }

        .c-columns--4 {
            column-count: 4;
        }
    }

    .c-content-overview,
    .c-content-overview p,
    .c-content-overview a {
        font-size: 20px;
        line-height: 30px;
        margin: 0 0 30px 0;
    }

    .c-link-bank {
        background-color: var(--interactive-100);
        border-radius: 8px;
        padding: 24px;
        margin: 24px 0;
    }

    .c-link-bank ul {
        margin-bottom: 0;
        padding-left: 0;
        column-count: 1;
        margin-left: 0;
        list-style: none;
    }

    .c-link-bank ul li {
        margin-left: 20px;
        margin-bottom: 10px;
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-link-bank ul {
            column-count: 2;
        }
    }

    .c-link-bank ul:last-of-type li a {
        word-break: break-word;
        text-decoration: none;
    }

    .c-link-bank__title {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0;
        color: #000;
        margin-bottom: 26px;
        text-align: center;
        font-weight: bold;
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-link-bank--left,
        .c-link-bank--right {
            margin: 25px 0;
        }

        .c-link-bank--left .c-link-bank__title,
        .c-link-bank--right .c-link-bank__title {
            text-align: left;
        }

        .c-link-bank--left ul,
        .c-link-bank--right ul {
            column-count: 2;
        }
    }

    .c-link-bank--right {
        column-count: 1;
        float: none;
        width: 100%;
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-link-bank--right {
            float: right;
            margin: 0 0 25px 20px;
            width: 40%;
        }

        .c-link-bank--right ul {
            column-count: 1;
        }
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-link-bank--right {
            float: none;
            width: 100%;
            margin: 25px 0;
        }

        .c-link-bank--right ul {
            column-count: 2;
        }
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-link-bank--right {
            float: right;
            margin: 0 0 25px 20px;
            width: 40%;
        }

        .c-link-bank--right ul {
            column-count: 1;
        }
    }

    .c-link-bank--left {
        column-count: 1;
        float: none;
        width: 100%;
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-link-bank--left {
            float: left;
            margin: 0 20px 25px 0;
            width: 40%;
        }

        .c-link-bank--left ul {
            column-count: 1;
        }
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-link-bank--left {
            float: none;
            width: 100%;
            margin: 25px 0;
        }

        .c-link-bank--left ul {
            column-count: 2;
        }
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-link-bank--left {
            float: left;
            margin: 0 20px 25px 0;
            width: 40%;
        }

        .c-link-bank--left ul {
            column-count: 1;
        }
    }

    .c-link-bank-complex {
        background-color: var(--secondary);
        padding: 45px 30px;
        margin: 25px 0;
        overflow: hidden;
    }

    .c-link-bank-complex ul {
        margin-bottom: 0;
        padding-left: 0;
        margin-left: 0;
    }

    .c-link-bank-complex ul li {
        margin-left: 20px;
        margin-bottom: 10px;
    }

    .c-link-bank-complex ul:last-of-type li a {
        word-break: break-word;
    }

    .c-link-bank-complex__title {
        font-size: 20px;
        line-height: 25px;
        color: var(--primary);
        text-align: center;
        margin-bottom: 30px;
        font-weight: 800;
    }

    .c-quote {
        border: none;
        margin: 60px 0;
        overflow: hidden;
        padding: 30px;
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-quote--right {
            float: right;
            margin: 0 0 30px 30px;
        }

        .c-quote--left {
            float: left;
            margin: 0 30px 30px 0;
        }

        .c-quote--left,
        .c-quote--right {
            width: 40%;
        }
    }
    .c-quote p,
    .c-quote a {
        font-weight: 500;
        margin: 0;
        font-family: var(--title-font);
        font-size: 23px;
        font-weight: bold;
        line-height: 48px;
        color: var(--gray-500);
    }
    .c-quote a {
        color: var(--interactive);
    }
    .c-quote__content,
    .c-quote__content p {
        font-weight: 500;
        margin: 0;
        font-family: var(--title-font);
        font-size: 23px;
        font-weight: bold;
        line-height: 48px;
        color: var(--gray-500);
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .c-quote__content,
        .c-quote__content p {
            font-size: 25px;
            margin: 0;
        }
    }

    .c-quote__source {
        position: relative;
        font-family: var(--copy-font);
        font-size: 16px;
        font-style: italic;
        margin: 10px 0 0 0;
        text-align: right;
        display: inline-block;
        float: right;
    }

    .c-quote__source::before {
        position: absolute;
        content: '-';
        left: -15px;
    }

    .alignleft,
    .alignright {
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .aligncenter {
        text-align: center;
        margin: 0 auto;
        display: block;
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        .alignleft,
        .alignright {
            min-width: 220px;
            max-width: 50%;
        }
        .alignleft {
            float: left;
            margin-right: 30px;
        }
        .alignright {
            float: right;
            margin-left: 30px;
        }
    }

    .c-cta {
        background: #fff;
        width: 100%;
        margin: 30px 0;
        padding: 40px 20px;
        border-radius: 3px;
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        border: 3px solid var(--primary-100);
        border-radius: 8px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .c-cta {
            margin: 60px 0;
            padding: 30px 60px;
            font-size: 16px;
        }
        .c-cta--left,
        .c-cta--right {
            width: 40%;
            padding: 20px;
        }

        .c-cta--left {
            float: left;
            margin: 0 20px 25px 0;
        }

        .c-cta--right {
            float: right;
            margin: 0 0 25px 20px;
        }
    }
    .c-cta-button {
        background: var(--interactive);
        font-family: var(--copy-font);
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        border: none;
        color: #fff;
        font-weight: bold;
    }
    .c-cta-button:hover {
        cursor: pointer;
        background: var(--interactive-400);
        transition: all 0.25s;
    }
    .c-cta-button:focus {
        outline: 0;
    }
    table {
        min-width: 100%;
        border-spacing: 1px !important;
        border-radius: 8px;
        margin: 40px 0 !important;
        display: block;
    }
    table th {
        min-width: 100%;
        background: var(--primary);
        color: #fff;
        padding: 16px;
        text-align: left;
        font-size: 16px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        table th {
            padding: 24px 16px;
            font-size: 18px;
        }
    }
    table tbody td {
        text-align: left !important;
        padding: 16px;
    }
    table tbody {
        border: 2px solid red !important;
        background: var(--gray-200);
        border-radius: 0 0 8px 8px !important;
    }
    table th:first-child {
        border-radius: 8px 0 0 0;
    }
    table th:last-child {
        border-radius: 0 8px 0 0;
    }
    table tbody tr:last-child td:last-child {
        border-radius: 0 0 8px 0;
    }
    table tbody tr:last-child td:first-child {
        border-radius: 0 0 0 8px;
    }
`;
