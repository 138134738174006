import React, {useState, useEffect, useRef, useMemo} from 'react';
import App from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {WPFetch} from 'aac-components/utils/fetch';
import menuItemCreator from '../lib/actions/menuItemCreator';
import AppContext from '../components/AppContext';
import HiddenOnMobile from '../components/HiddenOnMobile';
import AdminBar from 'aac-components/components/AdminBar';
import {initialAppState} from '../components/AppContext';
import StickyFooterContext, {
    initialStickyFooterState,
} from '../components/StickyFooter/StickyFooterContext';
import SeoHead from '../components/SeoHead';
import BreadcrumbSchema from 'aac-components/components/Schema/BreadcrumbSchema';
import CustomPageSchema from 'aac-components/components/Schema/CustomPageSchema';
import throttle, {debounce} from 'aac-components/utils/throttle';
import {getDynamicKeywords} from '../lib/dynamic-keywords';
import {getIpAddress} from 'aac-components/utils/getIpAddress';
import GeoTargetTabWrapper from '../components/GeoTargetTabWrapper';
import {getCookie, setCookie} from 'aac-components/utils/cookies';
import {useWpFetch} from '../lib/hooks/useFetch';
import {getMatchFacility} from 'aac-components/components/GeoTargetDrawerContent/helpers';
import {
    lazyLoadImagesClientside,
    loadStateFromLocalStorage,
    saveStateToLocalStorage,
    getCallTrackingNumber,
    getPageLang,
    updateHtmlLang,
    getSegmentNumber,
    getSessionStorageState,
} from '../lib/utils';
import Drift from '../components/Drift';
import DriftContext from '../components/Drift/DriftContext';

export default function MyApp(props) {
    const {Component, pageProps = {}, ipAddress, blockingData = {}} = props;

    // drift state
    const [driftLoaded, setDriftLoaded] = useState(false);
    const [isChatActive, setIsChatActive] = useState(false);

    // sticky footer state
    const [stickyFooterState, setStickyFooterState] = useState(initialStickyFooterState);
    const updateStickyFooterState = (newState) => {
        setStickyFooterState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };

    // router
    const router = useRouter();
    const {asPath, query} = router;
    const clearCache = Boolean(query?.clearCache);

    // refs
    const pagePropsRef = useRef(pageProps);
    const asPathRef = useRef(asPath);

    // state
    const [mounted, setMounted] = useState(false);
    const [state, setState] = useState(initialAppState);
    const updateState = (newState) => {
        setState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };

    /**
     * Updates callTrackingIntentScore, callTrackingNumber, and pagesScrolled50 in appState
     *
     * @param {number} score - current callTrackingIntentScore
     * @param {Array<string>} pagesScrolled50 - page where the user scrolle down 50%
     * @param {string} asPath - current page path
     * @param {Object} pageProps - additional data like acf, byline_info, etc.
     */
    const updateCallPool = (score, pagesScrolled50, asPath, pageProps) => {
        updateState({
            callTrackingIntentScore: score + 1,
            callTrackingNumber: getCallTrackingNumber(score + 1, asPath, pageProps),
            pagesScrolled50: pagesScrolled50 || state.pagesScrolled50 || [],
        });
    };

    /**
     *  Get ACF Options clientside to reduce data size in getInitial Props
     */
    const {data: acfOptions = {}} = useWpFetch(
        '/wordpress/acf/v3/options/options',
        clearCache,
    );
    useEffect(() => {
        updateState({acfOptions: acfOptions || {}});
    }, [acfOptions]);

    /**
     * componentDidMount
     * Merge history array into state from localStorage on any serverside navigation
     * Not necessary on clientside navigation, although you could
     */
    useEffect(() => {
        setMounted(true);
        const localStorageState = loadStateFromLocalStorage(state);
        updateState({
            ...localStorageState,
            callTrackingNumber: getCallTrackingNumber(
                localStorageState.callTrackingIntentScore,
                asPath,
                pageProps,
            ),
        });

        // Update call pool if vob abandon modal is shown
        const vobAbandonModalEventCallback = (event) => {
            // need call loadStateFromLocalStorage inside custom function to get the updated state
            const currentCallTrackingScore =
                loadStateFromLocalStorage(state).callTrackingIntentScore;
            if (event?.detail?.showAbandonModal && currentCallTrackingScore < 2) {
                updateCallPool(
                    currentCallTrackingScore,
                    null,
                    asPathRef.current,
                    pagePropsRef.current,
                );
            }
        };
        window.addEventListener('vobAbandonModalEvent', vobAbandonModalEventCallback);

        // Update call pool if user resvisits after closing the browser
        // To prevent call pool updating on page refresh, check lastVisitedTimestamp from localStorage and if it is past 5 seconds then upgrade call pool
        let timeout;
        const currentTimestamp = Date.now();
        const isReturningUser =
            localStorageState.lastVisitedTimestamp &&
            currentTimestamp - localStorageState.lastVisitedTimestamp > 5 * 1000;
        if (isReturningUser) {
            // add delay so the number updates after the other call pool update logic
            // this is because triggering updateState here doesn't reflect the updated state immediately in the other useEffect functions below
            timeout = setTimeout(() => {
                // need call loadStateFromLocalStorage inside custom function to get the updated state
                const currentCallTrackingScore =
                    loadStateFromLocalStorage(state).callTrackingIntentScore;
                if (currentCallTrackingScore < 2) {
                    updateCallPool(
                        currentCallTrackingScore,
                        null,
                        asPathRef.current,
                        pagePropsRef.current,
                    );
                }
            }, 500);
        }
        // since the beforeunload gets called for page refresh as well, use timestamp to track when the user last visited the site
        const handleBeforeUnload = (e) => {
            // need call loadStateFromLocalStorage inside custom function to get the updated states
            const localStorageState = loadStateFromLocalStorage(state);
            saveStateToLocalStorage({
                ...localStorageState,
                lastVisitedTimestamp: Date.now(),
            });
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            clearTimeout(timeout);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener(
                'vobAbandonModalEvent',
                vobAbandonModalEventCallback,
            );
        };
    }, []);

    /**
     * Updates state.isMobile if the user has resized the viewport over/under 992px
     */
    const reportWindowSize = () => {
        const isMobile = window && window.innerWidth <= 992;
        updateState({isMobile});
    };

    /**
     * Merge history array into state from localStorage on any serverside navigation
     * Not necessary on clientside navigations, although you could
     */
    useEffect(() => {
        const localStorageState = loadStateFromLocalStorage(state);

        if (typeof window !== 'undefined') {
            // images lazyload
            lazyLoadImagesClientside();

            // page lang
            const lang = getPageLang(asPath);
            window && lang == 'es' && updateHtmlLang(lang);
        }

        updateState({...localStorageState});
    }, [asPath]);

    /**
     * Check if cookie values need to be updated when certain state keys change
     * Memoized to attempt to avoid re-renders
     */
    const [cookieData, partialAppState] = useMemo(() => {
        const cookieData = {
            contentSegment: getCookie('aac_segment'),
            callTrackingIntentScore: getCookie('aac_intent'),
            geolocation: getCookie('aac_geolocationfac'),
        };

        const partialAppState = {
            contentSegment: state?.segment?.name,
            callTrackingIntentScore: state?.callTrackingIntentScore,
            geolocation: state?.sessionStorageState?.geolocation?.facility,
        };
        return [cookieData, partialAppState];
    }, [
        state?.segment,
        state?.sessionStorageState?.geolocation?.facility,
        state?.callTrackingIntentScore,
    ]);

    /**
     * Set certain appState keys to a cookie
     * So 3rd party script like Drift chat can read values
     */
    useEffect(() => {
        // compare cookieData with appState keys we need
        if (
            cookieData.contentSegment !== partialAppState.contentSegment ||
            cookieData.callTrackingIntentScore !==
                partialAppState.callTrackingIntentScore ||
            cookieData.geolocation !== partialAppState.geolocation
        ) {
            const setDriftCookies = throttle(() => {
                setCookie('aac_segment', state?.segment?.name);
                setCookie('aac_segmentnum', getSegmentNumber(state?.segment?.name));
                setCookie('aac_intent', state?.callTrackingIntentScore);
                setCookie(
                    'aac_geolocationfac',
                    state?.sessionStorageState?.geolocation?.facility,
                );
            }, 100);
            setDriftCookies();
        }
    }, [cookieData, partialAppState]);

    /**
     * Merge blockingData into state (menuItems)
     */
    useEffect(() => {
        updateState({...blockingData});
    }, [blockingData]);

    /**
     * Session storage for VET phone usage
     */
    useEffect(() => {
        const vetPhoneTargets = ['veteran', 'tricare', 'va-benefits', 'first-responder'];
        const isVetPhoneTarget =
            vetPhoneTargets?.filter((target) => asPath?.includes(target))?.length >= 1;
        const isVetSessionSet = getSessionStorageState()?.useVetPhone;

        if (isVetPhoneTarget && !isVetSessionSet) {
            const sessionStorageState = getSessionStorageState();
            window?.sessionStorage?.setItem(
                'AacApp',
                JSON.stringify({useVetPhone: true, ...sessionStorageState}),
            );
        }
    }, [asPath]);

    /**
     * Updates needed for when user changes pages (serverside or clientside navigation)
     */
    useEffect(() => {
        // scroll to the top after clientside navigation
        document.body.scrollTop = 0;
        // lazyload images not using next/image
        if (window?.lazyLoadInstance) {
            lazyLoadInstance?.update();
        }

        // record window size
        window &&
            (reportWindowSize(),
            window.addEventListener('resize', debounce(reportWindowSize, 500)));
        const dynamicKeywords = getDynamicKeywords(asPath);

        // get sessionStorage data
        const sessionStorageState = getSessionStorageState(state.sessionStorageState);
        // get localStorage data
        const localStorageState = loadStateFromLocalStorage(state);

        // check for homepage or insurance page
        const isHomepage = asPath === '/';
        const isInsurancePage =
            asPath?.includes('/insurance-coverage') ||
            asPath?.includes('/verify-insurance');

        // segment
        const segmentName = pageProps?.data?.acf?.content_segment || '';

        let callTrackingIntentScore = 0;
        // We want the homepage and insurance pages to both show high intent phone numbers
        // so we set intentScore to 2 here.
        if (isHomepage || isInsurancePage) {
            callTrackingIntentScore = 2;
        } else {
            callTrackingIntentScore = Math.max(
                localStorageState.callTrackingIntentScore,
                state.callTrackingIntentScore,
                getSegmentNumber(segmentName) >= 4 ? 1 : 0,
            ); // get highest score from local storage, current state or score based on content segment
        }

        // update state.history with new slug user visits
        setState((prevState) => {
            const newState = {
                ...prevState,
                callTrackingIntentScore,
                callTrackingNumber: getCallTrackingNumber(
                    callTrackingIntentScore,
                    asPath,
                    pageProps,
                ),
                dynamicKeywords,
                segment: {
                    name: segmentName,
                    number: getSegmentNumber(segmentName),
                },
                history: [prevState.history.slice(-1)[0], asPath],
            };

            // ensures merging sessionStorage to state only happens once
            if (!mounted) {
                newState.sessionStorageState = sessionStorageState;
            }

            return newState;
        });

        // update refs with new values
        pagePropsRef.current = pageProps;
        asPathRef.current = asPath;

        // Update call pool if scrolled more than 50%
        const handleScroll = throttle(() => {
            const html = document.documentElement;
            const scrollDepth =
                (html['scrollTop'] / (html['scrollHeight'] - html.clientHeight)) * 100;

            const localStorageState = loadStateFromLocalStorage(state);
            const currentCallTrackingScore = localStorageState.callTrackingIntentScore;
            const pagesScrolled50 = localStorageState.pagesScrolled50 || [];
            if (
                scrollDepth > 50 &&
                !pagesScrolled50.includes(asPath) &&
                currentCallTrackingScore < 2
            ) {
                // keep track if page was scrolled 50%, eventually stored in local storage to keep the data after page refresh
                updateCallPool(
                    currentCallTrackingScore,
                    [...pagesScrolled50, asPath],
                    asPathRef.current,
                    pagePropsRef.current,
                );
            }
            return;
        }, 100);

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [asPath]);

    /**
     * For adding Emotions AI grouping as a user property in heap
     */
    useEffect(() => {
        if (window && window?.heap) {
            const delay = setTimeout(() => {
                // get emotions ai segment
                const storage = window?.localStorage?.getItem('ABTastyData') || '';
                const storageObj = JSON?.parse(storage) || {};
                const emotionsAiSegment = storageObj?.eai?.segment;
                // add user property to heap
                heap?.addUserProperties({
                    EAI_Segment: emotionsAiSegment,
                });
            }, 32000);
            return () => clearTimeout(delay);
        }
    }, []);

    useEffect(() => {
        window && window?.CallTrk?.swap();
    }, [state?.callTrackingNumber?.href]);

    /**
     * Save history to localStorage
     */
    useEffect(() => {
        // save new state to localStorage so we can track user history
        if (state?.history?.length > 0) {
            saveStateToLocalStorage(state);
        }
    }, [state?.history]);

    /**
     * Helper function to update sessionStorage
     */
    const setSessionStorageKey = (keyId, value) => {
        const sessionStorageState = {
            ...state.sessionStorageState,
            [keyId]: value,
        };
        window &&
            window.sessionStorage &&
            window.sessionStorage.setItem('AacApp', JSON.stringify(sessionStorageState));
        updateState({sessionStorageState});
    };

    useEffect(() => {
        saveStateToLocalStorage(state);
        const callTrackingNumber = getCallTrackingNumber(
            state.callTrackingIntentScore,
            asPath,
            pageProps,
        );
    }, [state.callTrackingIntentScore]);

    const saveGeolocationToCookie = (data) => {
        // max-age 1 year (365*60*60*34)
        document.cookie = `aacAppState=${encodeURIComponent(
            JSON.stringify(data),
        )}; max-age=31536000; path=/`;
    };

    // geo location
    const getUserGeoLocation = async () => {
        // ::1 is value for local env
        if (!ipAddress || ipAddress === '::1') return;

        try {
            const response = await fetch('/cp-aac/api/geolocation', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify({ipAddress}),
            });
            const geolocation = await response.json();
            let geolocationWithMatch = {};
            if (geolocation?.data) {
                // Check if user is within distance of a facility
                const matchFacility = getMatchFacility(geolocation?.data);
                geolocationWithMatch = {
                    ...geolocation?.data,
                    facility: matchFacility?.siteKey,
                    distance: matchFacility?.distance,
                };

                const localStorageState = loadStateFromLocalStorage(state);
                const currentCallTrackingScore = Math.max(
                    localStorageState.callTrackingIntentScore,
                    state.callTrackingIntentScore,
                );
                if (matchFacility.siteKey && currentCallTrackingScore < 2) {
                    updateCallPool(
                        currentCallTrackingScore,
                        null,
                        asPathRef.current,
                        pagePropsRef.current,
                    );
                }
            }
            saveGeolocationToCookie({
                facility: geolocationWithMatch?.facility,
                distance: geolocationWithMatch?.distance,
            });
            setSessionStorageKey(
                'geolocation',
                geolocation?.data ? geolocationWithMatch : geolocation,
            );
        } catch (error) {
            console.log(error);
        }
    };
    /**
     * Get user's geolocation based off their IP
     */
    useEffect(() => {
        // Fetch user's geolocation data only if it wasn't already fetched
        // We have to grab data directly from window.sessionStorage here
        const sessionStorageState = getSessionStorageState();
        if (Object.keys(sessionStorageState?.geolocation || {}).length === 0) {
            getUserGeoLocation();
        }
    }, [ipAddress]);

    const {
        data: {
            id = 0,
            acf: {aac_json_schema = '', content_segment = ''} = {},
            tags = [],
            type = '',
        } = {},
    } = pageProps;

    const segment = ['staff', 'contributor']?.includes(type)
        ? 'info-pr'
        : content_segment
        ? content_segment
        : 'other';

    const {menuItems, footerMenuItems, isLoggedIn} = blockingData;

    const tagNames = tags.map((tag) => {
        return tag.name;
    });
    const tagString = tagNames.join(', ');

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, minimal-ui"
                />
                <meta property="article:tag" content={tagString}></meta>
            </Head>
            <SeoHead {...pageProps} />
            <BreadcrumbSchema />
            <CustomPageSchema schema={aac_json_schema} />
            <div id="app__root">
                <AppContext.Provider value={{...state, menuItems, pageProps}}>
                    <DriftContext.Provider
                        value={{
                            driftLoaded,
                            setDriftLoaded,
                            isChatActive,
                            setIsChatActive,
                        }}>
                        <StickyFooterContext.Provider
                            value={{stickyFooterState, updateStickyFooterState}}>
                            <Drift />
                            <div
                                id="page-segment"
                                className={segment}
                                data-post-id={id}
                                data-segment={segment}
                                data-segment-number={getSegmentNumber(segment)}
                                data-tags={tagString}
                            />
                            {isLoggedIn && (
                                <HiddenOnMobile>
                                    <AdminBar
                                        pageProps={pageProps}
                                        loggedInUser={blockingData?.loggedInUser}
                                    />
                                </HiddenOnMobile>
                            )}
                            <Component {...pageProps} />
                        </StickyFooterContext.Provider>
                    </DriftContext.Provider>
                </AppContext.Provider>
            </div>
        </>
    );
}
/**
 * Execute any code that should run before the Application is rendered
 * @param {Object} appContext
 */
MyApp.getInitialProps = async (appContext) => {
    // get your appProps, needed per next.js docs https://nextjs.org/docs/advanced-features/custom-app
    const appProps = await App.getInitialProps(appContext);

    // See if the user is logged into the wordpress admin dashboard
    const {ctx = {}} = appContext;
    const settings =
        (ctx?.req?.headers?.cookie &&
            ctx.req.headers.cookie
                .split('; ')
                .find((row) => row.startsWith('wordpress_logged_in_'))) ||
        false;
    const isLoggedIn = settings !== false;
    const loggedInUser = (settings && settings?.split('=')?.[1]?.split('%'))?.[0] || '';
    const clearCache = Boolean(ctx?.req?.query?.clearCache);

    // Get user's IP
    const {req} = ctx;
    //const ipAddress = getIpAddress(req);
    const ipAddress = req?.query?.ipAddress || getIpAddress(req);

    // Get our blocking data. Menus and global ACF site options
    const [menuItems = [], footerMenuItems = []] = await Promise.all(
        ['/wordpress/aac/v1/menu/main-menu', '/wordpress/aac/v1/menu/footer'].map((url) =>
            WPFetch(url, clearCache)
                .then(({data}) => {
                    if (Array.isArray(data)) {
                        return data.map(menuItemCreator);
                    }
                    return data;
                })
                .catch((error) => console.log(error)),
        ),
    );

    return {
        ...appProps,
        ipAddress,
        blockingData: {
            menuItems,
            footerMenuItems,
            isLoggedIn,
            loggedInUser,
        },
    };
};
