import Link from 'next/link';
import MaxWidth from '../MaxWidth';
import {BREAKPOINT} from '../../lib/styles';
import IconInstagram from 'aac-components/components/Icons/Social/IconInstagram';
import IconFacebook from 'aac-components/components/Icons/Social/IconFacebook';
import IconLinkedin from 'aac-components/components/Icons/Social/IconLinkedin';
import IconTwitter from 'aac-components/components/Icons/Social/IconTwitter';
import IconTiktok from 'aac-components/components/Icons/Social/IconTiktok';
import IconYoutube from 'aac-components/components/Icons/Social/IconYoutube';
import Image from 'next/image';
import TermsFooter from './termsFooter';
import {
    corporatePhone,
    corporatePhoneLink,
    twitterLink,
    facebookLink,
    linkedinLink,
    instagramLink,
    tiktokLink,
    youtubeLink,
} from '../../lib/globals';
import {useContext, useEffect, useState} from 'react';
import AppContext from '../AppContext';
import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';
import {getOrganizedMenu} from '../Navigation/utils';
import PreFooter from '../PreFooter';
import {useRouter} from 'next/router';

export const hasChildren = (arr) => {
    return Array.isArray(arr?.child_items) && arr?.child_items?.length >= 1;
};

const Footer = (props) => {
    const {isMobile, footerMenuItems} = useContext(AppContext);

    const menu = getOrganizedMenu(footerMenuItems);
    const {asPath} = useRouter();

    // account for prefooter if there is one
    const [pagePrefooter, setPagePrefooter] = useState({hasPrefooter: true, height: 0});
    useEffect(() => {
        const prefooter = document.getElementById('prefooter');
        if (prefooter) {
            setPagePrefooter((prevState) => ({
                ...prevState,
                hasPrefooter: true,
                height: prefooter?.offsetHeight,
            }));
        } else {
            setPagePrefooter((prevState) => ({
                ...prevState,
                hasPrefooter: false,
            }));
        }
    }, [asPath]);

    const iconSize = isMobile ? '20' : '30';

    return (
        <>
            <div style={{position: 'relative'}}>
                <PreFooter {...props} />
            </div>
            <footer>
                <div className="footer__container">
                    <MaxWidth style={{padding: 0}}>
                        {isMobile ? (
                            <FooterMobile menu={menu} />
                        ) : (
                            <FooterDesktop menu={menu} />
                        )}
                    </MaxWidth>
                    <div className="footer__company">
                        <MaxWidth>
                            <div className="footer__company--container">
                                <div>
                                    <Image
                                        src="/static/aac_icon_white.svg"
                                        width={isMobile ? 75 : 100}
                                        height={isMobile ? 75 : 100}
                                        alt="American Addiction Centers"
                                    />
                                </div>
                                <div className="footer__company--details">
                                    <div className="bold">American Addiction Centers</div>
                                    <div>
                                        500 Wilson Pike Circle, Suite 360 Brentwood, TN
                                        37027
                                    </div>
                                    <div>
                                        <a
                                            style={{
                                                textDecoration: 'none',
                                                fontWeight: 'bold',
                                            }}
                                            title={corporatePhone}
                                            href={corporatePhoneLink}>
                                            {corporatePhone}
                                        </a>
                                    </div>
                                    <div>
                                        <Link href="/addresses" legacyBehavior>
                                            <a title="All Addresses">All Addresses</a>
                                        </Link>
                                    </div>
                                </div>
                                <div className="footer__company--accreditations">
                                    <div>
                                        <Link
                                            href="/accreditations"
                                            title="CARF Gold Seal">
                                            <Image
                                                src="/static/Logo_CARF_GoldSeal.png"
                                                width={55}
                                                height={55}
                                                alt="CARF Gold Seal"
                                            />
                                        </Link>
                                    </div>
                                    <div>
                                        <Link
                                            href="/accreditations"
                                            title="Joint Commission">
                                            <Image
                                                src="/static/joint-commission.png"
                                                width={55}
                                                height={55}
                                                alt="Joint Commission"
                                            />
                                        </Link>
                                    </div>
                                    <div>
                                        <a
                                            href="https://www.hirevets.gov/"
                                            target="_blank"
                                            title="HIRE Vets Medallion Award Program">
                                            <Image
                                                src="/static/HIRE-Vet-medallion.png"
                                                width={55}
                                                height={55}
                                                alt="HIRE Vet Medallion"
                                            />
                                        </a>
                                    </div>
                                    <div>
                                        <Link
                                            href="/accreditations"
                                            title="Americas Greatest Workplaces">
                                            <Image
                                                src="/static/Americas_Greatest_Workplaces_2024.png"
                                                alt="Americas Greatest Workplaces"
                                                fill
                                                style={{objectFit: 'cover'}}
                                                sizes="80px"
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="footer__company--social">
                                    <a
                                        href={twitterLink}
                                        target="_blank"
                                        title="American Addiction Centers Twitter"
                                        rel="noreferrer nofollow">
                                        <div className="social-icon">
                                            <IconTwitter
                                                fill="var(--primary-400)"
                                                width={iconSize}
                                                height={iconSize}
                                            />
                                        </div>
                                    </a>
                                    <a
                                        href={facebookLink}
                                        target="_blank"
                                        title="American Addiction Centers Facebook"
                                        rel="noreferrer nofollow">
                                        <div className="social-icon">
                                            <IconFacebook
                                                fill="var(--primary-400)"
                                                width={iconSize}
                                                height={iconSize}
                                            />
                                        </div>
                                    </a>
                                    <a
                                        href={linkedinLink}
                                        target="_blank"
                                        title="American Addiction Centers Linkedin"
                                        rel="noreferrer nofollow">
                                        <div className="social-icon">
                                            <IconLinkedin
                                                fill="var(--primary-400)"
                                                width={iconSize}
                                                height={iconSize}
                                            />
                                        </div>
                                    </a>
                                    <a
                                        href={instagramLink}
                                        target="_blank"
                                        title="American Addiction Centers Instagram"
                                        rel="noreferrer nofollow">
                                        <div className="social-icon">
                                            <IconInstagram
                                                fill="var(--primary-400)"
                                                width={iconSize}
                                                height={iconSize}
                                            />
                                        </div>
                                    </a>
                                    <a
                                        href={youtubeLink}
                                        target="_blank"
                                        title="American Addiction Centers Youtube"
                                        rel="noreferrer nofollow">
                                        <div className="social-icon">
                                            <IconYoutube
                                                fill="var(--primary-400)"
                                                width={iconSize}
                                                height={iconSize}
                                            />
                                        </div>
                                    </a>
                                    <a
                                        href={tiktokLink}
                                        target="_blank"
                                        title="American Addiction Centers TikTok"
                                        rel="noreferrer nofollow">
                                        <div className="social-icon">
                                            <IconTiktok
                                                fill="var(--primary-400)"
                                                width={iconSize}
                                                height={iconSize}
                                            />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </MaxWidth>
                        <TermsFooter />
                    </div>
                </div>

                <style jsx>
                    {`
                        footer {
                            background: var(--primary-400);
                            color: #fff;
                            width: 100%;
                            position: relative;
                        }
                        @media screen and (min-width: ${BREAKPOINT}) {
                            footer {
                                margin-top: ${pagePrefooter?.hasPrefooter
                                    ? `-${pagePrefooter?.height / 3}px`
                                    : 0};
                                padding-top: ${pagePrefooter?.hasPrefooter
                                    ? `${pagePrefooter?.height / 3}px`
                                    : 0};
                            }
                        }
                        .footer__container {
                            background: var(--primary-400);
                        }
                        .footer__company a {
                            color: var(--primary-100);
                            font-size: 14px;
                        }

                        .footer__company--container {
                            display: block;
                            text-align: center;
                            padding: 0 16px;
                        }
                        @media screen and (min-width: ${BREAKPOINT}) {
                            .footer__company--container {
                                display: grid;
                                font-size: 14px;
                                grid-template-columns: 100px auto auto auto;
                                grid-gap: 30px;
                                justify-content: center;
                                text-align: left;
                                margin: 80px 0 60px 0;
                                padding: 0;
                            }
                        }
                        .footer__company--details {
                            margin-bottom: 40px;
                        }
                        @media screen and (min-width: ${BREAKPOINT}) {
                            .footer__company--details {
                                margin-bottom: 0;
                            }
                        }
                        .footer__company--details > div {
                            margin-bottom: 5px;
                        }
                        .footer__company--accreditations {
                            display: grid;
                            grid-template-columns: repeat(4, auto);
                            grid-gap: 8px;
                            justify-content: center;
                            align-items: center;
                            padding: 0 8px;
                            margin: 15px 0;
                        }
                        @media screen and (min-width: ${BREAKPOINT}) {
                            .footer__company--accreditations {
                                margin: 0;
                                grid-gap: 8px;
                                grid-template-columns: repeat(4, auto);
                            }
                        }
                        .footer__company--accreditations > div {
                            position: relative;
                            width: 60px;
                            height: 60px;
                        }
                        .footer__company--social {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            grid-gap: 4px;
                            padding-bottom: 40px;
                        }
                        @media screen and (min-width: ${BREAKPOINT}) {
                            .footer__company--social {
                                grid-gap: 8px;
                                padding-bottom: 0;
                            }
                        }
                        .social-icon {
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                            background: var(--primary-100);
                            margin: 0 3px;
                        }
                        @media screen and (min-width: ${BREAKPOINT}) {
                            .social-icon {
                                width: 50px;
                                height: 50px;
                            }
                        }
                    `}
                </style>
            </footer>
        </>
    );
};
export default Footer;
