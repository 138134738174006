import React, {useContext, useEffect, useState} from 'react';
import {NavContext} from '.';
import Link from 'next/link';
import DriftContext from '../Drift/DriftContext';
import Image from 'next/image';
import UserWay from 'aac-components/components/UserWay';
import IconChat from 'aac-components/components/Icons/Communication/IconChat';
import IconSearch from 'aac-components/components/Icons/Basic/IconSearch';
import IconMenu from 'aac-components/components/Icons/Basic/IconMenu';
import {useRouter} from 'next/router';
import {launchChat} from '../Drift/helpers';
import DesktopSubMenu from './DesktopSubMenu';
import AppContext from '../AppContext';
import SegmentBanner from '../SegmentBanner';
import styled from 'styled-components';
import Button from 'aac-components/components/Button';

const subMenuItems = [
    {title: 'Our Locations', ctaIndex: 1},
    {title: 'Insurance', ctaIndex: 2},
    {title: 'Admissions', ctaIndex: 5},
    {title: 'Treatment', ctaIndex: 0},
    {title: 'About Us', ctaIndex: 3},
];

const DesktopNav = () => {
    const {driftLoaded} = useContext(DriftContext);
    const {asPath} = useRouter();

    const {
        acfOptions: {acf: {nav_ctas: {cta: navCtas = []} = {}} = {}} = {},
        pageProps: {data: {template = ''} = {}} = {},
    } = useContext(AppContext);
    const {menu, setIsNavDrawerOpen, setActiveSecondaryMenuId, searchMenuId} =
        useContext(NavContext);

    const isCompliancePage = template === 'compliance.php';

    // sub menu context
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const subMenu = subMenuItems.reduce((acc, curr) => {
        const match = menu.filter((item) => item?.title === curr?.title);
        acc.push({menu: match?.[0], cta: navCtas?.[curr?.ctaIndex]});
        return acc;
    }, []);

    // handle open delay
    let timer;
    const handleOpenSubMenu = (itemIndex) => {
        timer = setTimeout(() => {
            setActiveSubMenu(itemIndex);
        }, 250);
    };
    const handleCloseSubMenu = () => {
        setActiveSubMenu(null);
        if (timer) {
            clearTimeout(timer);
        }
    };

    // close when asPath changes
    useEffect(() => {
        setActiveSubMenu(null);
        return () => clearTimeout(timer);
    }, [asPath]);

    useEffect(() => {
        if (typeof window !== 'undefined' && window?.CallTrk && activeSubMenu) {
            window.CallTrk.swap();
        }
    }, [activeSubMenu]);

    return (
        <>
            <nav className="desktop-nav" onMouseLeave={handleCloseSubMenu}>
                <div className="desktop-nav__container">
                    <div>
                        <Link href="/" title="Home">
                            <div
                                className="desktop-nav__logo"
                                onMouseEnter={handleCloseSubMenu}>
                                <Image
                                    src="/aac-common-bucket/aac-lockup-horizontal-color-v2.svg"
                                    style={{objectFit: 'contain'}}
                                    alt={'American Addiction Centers'}
                                    sizes="100vw"
                                    fill
                                    unoptimized
                                />
                            </div>
                        </Link>
                    </div>

                    <div className="desktop-nav__items">
                        {subMenuItems.map((item, index) => {
                            return (
                                <div
                                    key={item?.title}
                                    className={activeSubMenu === index ? 'active' : ''}
                                    onMouseEnter={() => handleOpenSubMenu(index)}>
                                    {item?.title}
                                </div>
                            );
                        })}
                        <Button
                            key="get-help"
                            className={activeSubMenu === 6 ? 'active' : ''}
                            onClick={() => handleOpenSubMenu(6)}>
                            Get Help
                        </Button>
                    </div>
                    <div className="desktop-nav__right" onMouseEnter={handleCloseSubMenu}>
                        <div className="desktop-nav__icons">
                            <div id="desktop-nav__icons--userway">
                                <UserWay />
                            </div>
                            <div>
                                {driftLoaded && !isCompliancePage && (
                                    <div
                                        id="desktop-nav__icons--chat"
                                        style={{cursor: 'pointer'}}
                                        onClick={launchChat}>
                                        <IconChat fill="var(--primary-300)" />
                                    </div>
                                )}
                            </div>
                            <div
                                id="desktop-nav__icons--search"
                                onClick={() => {
                                    setActiveSecondaryMenuId(searchMenuId),
                                        setIsNavDrawerOpen(true);
                                }}
                                style={{cursor: 'pointer'}}>
                                <IconSearch fill="var(--primary-300)" />
                            </div>
                            <div
                                id="desktop-nav__icons--menu"
                                onClick={() => {
                                    setIsNavDrawerOpen(true);
                                }}
                                style={{cursor: 'pointer'}}>
                                <IconMenu fill="var(--primary-300)" />
                            </div>
                        </div>
                    </div>
                    <DesktopSubMenu
                        show={activeSubMenu !== null}
                        menu={subMenu?.[activeSubMenu]?.menu}
                        menuCta={subMenu?.[activeSubMenu]?.cta}
                        setActivesubMenu={setActiveSubMenu}
                        activeSubMenu={activeSubMenu}
                        subMenuItems={subMenuItems}
                        showTestSubNav={activeSubMenu === 6}
                    />
                </div>
                <style jsx>
                    {`
                        :global(body.aac .next-steps-nav__desktop) {
                            box-shadow: ${activeSubMenu === null
                                ? 'var(--box-shadow)'
                                : 'none'} !important;
                        }
                        .desktop-nav {
                            padding: 18px 15px;
                            border-bottom: 1px solid var(--gray-200);
                        }
                        .desktop-nav__container {
                            max-width: 1440px;
                            margin: 0 auto;
                            display: grid;
                            grid-template-columns: 260px 1fr auto;
                            grid-gap: 80px;
                            justify-content: space-between;
                        }
                        .desktop-nav__logo {
                            position: relative;
                            height: 50px;
                            min-width: 260px;
                            margin-right: 400px;
                        }

                        .desktop-nav__right {
                            display: grid;
                            grid-template-columns: auto auto;
                            align-items: center;
                        }
                        .desktop-nav__icons {
                            display: grid;
                            grid-template-columns: repeat(4, auto);
                            align-items: center;
                            justify-content: space-between;
                            grid-gap: 24px;
                        }
                        .desktop-nav__items {
                            display: flex;
                            grid-gap: 16px;
                            color: var(--primary);
                            font-size: 16px;
                            font-weight: bold;
                            align-items: center;
                            justify-content: space-between;
                            text-transform: uppercase;
                            cursor: pointer;
                            margin: 0 auto;
                            min-width: 100%;
                        }

                        .desktop-nav__items div {
                            border-bottom: 2px solid #fff;
                            padding-bottom: 4px;
                            text-align: center;
                        }
                        .desktop-nav__items div.active {
                            border-bottom: 2px solid var(--primary);
                        }
                    `}
                </style>
            </nav>
        </>
    );
};
export default DesktopNav;

const LinkStyles = styled.div`
    a {
        color: var(--primary);
        font-size: 16px;
        font-weight: bold;
        border-bottom: 2px solid #fff;
        padding-bottom: 4px;
        text-decoration: none;
        cursor: pointer;
    }
    a:hover {
        border-bottom: 2px solid var(--primary);
    }
`;
